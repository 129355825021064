import React, { useEffect } from "react";
import { Container, Grid, Typography, Box } from "@mui/material";
import ServiceCard from "./serviceCard";
import services from "../data/data";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles

const ServicesSection = () => {
  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 500, // Animation duration in milliseconds
      easing: "ease-in-out", // Easing style
      once: true, // Animation runs only once
    });
  }, []);

  return (
    <>
      <Box sx={{ py: 8, backgroundColor: "#fff" }} id="services-section">
        <Container maxWidth="lg">
          {/* Section Title */}
          <Box
            sx={{ textAlign: "center", mb: 6 }}
            data-aos="fade-up" // Fade-up animation for the title
          >
            <Typography
              variant="h3"
              component="h2"
              gutterBottom
              sx={{ fontWeight: "bold", fontFamily: "'Poppins', sans-serif" }}
            >
              Our Services
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              sx={{ maxWidth: "600px", mx: "auto", mb: 4 }}
            >
              Tailored services to help your business thrive in the digital
              landscape with cutting-edge technology.
            </Typography>
          </Box>

          {/* Service Cards */}
          <Grid container spacing={4}>
            {services.map((service, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={index}
                data-aos="zoom-in" // Zoom-in animation for each card
                data-aos-delay={index * 100} // Delay for a staggered effect
              >
                <ServiceCard service={service} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default ServicesSection;
